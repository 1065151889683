<template>
  <div class="content-box">
    <div class="content-header">
      <el-button type="plain" class="cotent-btn-big"  size="medium" icon="el-icon-plus" @click="handleEdit">新增app排版</el-button>
      <span class="merchant--name">{{brandName || ""}}</span>
    </div>
    <div class="table-list">
      <el-table :data="tableData" style="width: 100%" max-height="700" :header-cell-style="headerCellStyle" border
        v-loading="loading" :cell-style="cellStyle">

        <el-table-column prop="id" label="主键"  width="60">
        </el-table-column>

        <el-table-column prop="title" label="标题" width="80">
        </el-table-column>

        <el-table-column prop="subTitle" label="子标题" width="80">
        </el-table-column>
        <el-table-column prop="groupName" label="分组类型" width="110">
          <template slot-scope="scope">
            <div class="device-online" >{{ getGroupName(scope.row.groupType) }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="icon" label="显示logo" width="100">
          <template slot-scope="scope">
            <img v-if="scope.row.icon" :src="scope.row.icon" class="icon-avatar" />
          </template>
        </el-table-column>
        <el-table-column prop="jumpPath" label="跳转的路径">
        </el-table-column>
        <el-table-column v-if="merchantId" prop="useLog.hideStatus" label="启用状态" width="120">
          <template #default="{row}">
            {{
              row.publicType ? (row.useLog?.hideStatus ? "否" : "是" ) : "是"
            }}
          </template>
        </el-table-column>
        <el-table-column prop="publicType" label="公共属性" width="120">
          <template #default="{row}">
            {{ row.publicType ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序"  width="60">
          <template #default="{row}">
            {{row.publicType && merchantId ? row.useLog?.sort : row.sort}}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button v-if="!scope.row.publicType" type="text" @click="handleDelete(scope.row)"  class="danger-color">删除</el-button>
          </template>
        </el-table-column>

      </el-table>

      <!-- 分页 -->
      <el-pagination style="margin-top: 20px" background @size-change="handleSizeChange"
        @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="pageSizes" :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="itemTotal"></el-pagination>

      <el-dialog title="新增/编辑 app排版" :visible.sync="dialogVisible" width="460px" :before-close="dialogClose">
        <div class="edit-box">
          <el-form label-position="left" label-width="100px" :model="formData" ref="validateForm" :rules="rules">
            <el-form-item label="app标题:" prop="title">
              <el-input :disabled="merchantAppComposeDisabledFlag" v-model="formData.title" class="dialogInput" placeholder="app标题" maxlength="35">
              </el-input>
            </el-form-item>
   
            <el-form-item label="app子标题:" prop="subTitle">
              <el-input :disabled="merchantAppComposeDisabledFlag" v-model="formData.subTitle" class="dialogInput" placeholder="app子标题" maxlength="35">
              </el-input>
            </el-form-item>
         
            <el-form-item label="logo上传:" prop="img">
          <div class="avatar-uploader avatar-uploader-box">
            <el-upload
              :action="$uploadImg+'icon'"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
              :on-success="onSuccess"
              :disabled="merchantAppComposeDisabledFlag"
            >
              <img v-if="formData.icon" :src="formData.icon" class="icon-avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon avatar-uploader-icon-form"></i>
            </el-upload>
          </div>
          <p class="avatar-desc">仅支持JPG、PNG两种格式，图片大小不得超过500kb</p>
        </el-form-item>

            <el-form-item label="logo路径:" prop="icon">
              <el-input :disabled="merchantAppComposeDisabledFlag" v-model="formData.icon" class="dialogInput" placeholder="logo路径" maxlength="135">
              </el-input>
            </el-form-item>
            <el-form-item label="跳转的地址:" prop="jumpPath">
              <el-input :disabled="merchantAppComposeDisabledFlag" v-model="formData.jumpPath" class="dialogInput" placeholder="跳转的地址">
              </el-input>
            </el-form-item>
            <el-form-item v-if="merchantAppComposeDisabledFlag" label="排版顺序:" prop="useLog.sort">
              <el-input v-model="formData.useLog.sort" class="dialogInput"  maxlength="35"  placeholder="数字最小的在最前" > 
              </el-input>
            </el-form-item>
            <el-form-item v-else label="排版顺序:" prop="sort">
              <el-input v-model="formData.sort" class="dialogInput"  maxlength="35"  placeholder="数字最小的在最前" > 
              </el-input>
            </el-form-item>
            <el-form-item label="分组类型:" prop="groupType">
              <el-select :disabled="merchantAppComposeDisabledFlag" clearable v-model="formData.groupType" class="dialogInput" placeholder="分组类型">
                <el-option v-for="item in groupTypeList" :key="item.groupType" :label="item.groupTypeName" :value="item.groupType">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="公共属性:" prop="publicType" v-if="!merchantId">
              <el-switch
                :disabled="merchantId"
                v-model="formData.publicType"
                active-color="#13ce66"
                inactive-color="#DCDFE6">
                </el-switch>
            </el-form-item>
            <el-form-item label="启用:" prop="useLog.preHideStatus" v-if="merchantAppComposeDisabledFlag">
              <el-switch
                v-model="formData.useLog.preHideStatus"
                active-color="#13ce66"
                inactive-color="#DCDFE6">
              </el-switch>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="dialogClose">取消</el-button>
            <el-button type="primary" @click="dialogConfirm">提交</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {  Mixins, Component, Watch } from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";

import {
  list,
  saveAppCompose,
  appComposeDelete
} from "@/api/appCompose";



// 工具
import deepCopy from "@/utils/deepCopy";

const initFormData = {
  title: "",
  subtitle: "",
  icon: "",

};

@Component
export default class appList extends Mixins(loading, tablePagination) {

  merchantId = "";
  brandName = "";

  storeName = "";
  ownerName = "";
  phone = "";
  tableData = [];
  itemTotal = 0;
  dialogVisible = false;
  formData = {
    title: "",
    subtitle: "",
    icon: "",
    publicType: true,
    hideStatus: false,
    useLog: {
      composeId: "",
      hideStatus: false,
      preHideStatus: true,
      merchantId: this.merchantId,
      sort: 0,
    },
  };

  appList = [];
  rules = {
    title: [
      { required: true, message: 'app标题', trigger: 'blur' }
    ],
    subTitle: [
      { required: true, message: '子标题', trigger: 'blur' }
    ]
  };
 groupTypeList=[{groupType:1,groupTypeName:"智能体测系统"},{groupType:2,groupTypeName:"智能调理系统"},{groupType:3,groupTypeName:"其他"}];
  mounted() {
  }

  get merchantAppComposeFlag() {
    return this.merchantId;
  }

  get merchantAppComposeDisabledFlag() {
    return Boolean(this.merchantAppComposeFlag && this.formData.publicType && this.formData.id);
  }

  @Watch("$route", { immediate: true })
  routechange() {
    const path = this.$route.path;
    if (["/appCompose", "/merchantAppCompose"].includes(path)) {
      this.brandName = this.$route.query.brandName;
      this.merchantId = this.$route.query.merchantId;
      this.getData();
    }
  }


  getData() {
    this.showLoading();
    list({
      title: this.title,
      subTitle: this.subTitle,
      pageNum: this.currentPage,
      pageSize: this.pageSize,
      merchantId: this.merchantId || 0,
    }).then(res => {
      this.hideLoading();
      this.tableData = res.data.list;
      this.itemTotal = res.data.total;
      this.tableData.forEach((el) => {
        el.useLog = el.useLog || {};
        el.useLog.preHideStatus = !el.useLog?.hideStatus;
      })
    }).finally(() => {
      this.hideLoading();
    });
  }
   // 上传成功
   onSuccess(res, file) {
    this.formData.icon = this.$uploadImgUrl + "/" + res.data.url;
    this.formData = deepCopy(this.formData);
  }
   // 图片上传之前限制
   beforeAvatarUpload(file) {
    const isLt1M = file.size / 1024 / 1024 < 0.5;
    if (!isLt1M) {
      this.$message.error("上传头像图片大小不能超过 500k!");
    }

    return isLt1M;
  }

  //重置分页参数
  resetPages() {
    this.currentPage = 1;
  }
  getGroupName(groupType) {
    switch (groupType) {
      case 1:
        return "智能体测系统";
      case 2:
        return "智能调理系统";
      case 3:
        return "其他功能";
      default:
        return "-";
    }
  }

  // 头部输入
  changeValue(val, type) {
    this[type] = val;
  }

  // 搜索
  handleSearch() {
    this.resetPages();
    this.getData();
  }
  handleEdit(row) {
    let { id, title, subTitle, sort, icon, groupType,jumpPath, publicType = true, useLog = {} } = row;
    if (!useLog) useLog = {};
    this.formData = deepCopy({
      id,
      title,
      subTitle,
      sort,
      icon,
      jumpPath,
      groupType,
      publicType,
      useLog,
    });
    this.dialogVisible = true;
  }
  handleDelete(row){
    this.$confirm(`是否要删除该用户（编号${row.id}） ?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    }).then(() => {
      appComposeDelete({
        id: row.id,
      }).then((res) => {
        this.getData();
        this.$message.success("删除成功");
      });
    });
  }


  dialogClose() {
    this.formData = deepCopy(initFormData);
    this.$refs['validateForm'].resetFields();
    this.dialogVisible = false;
  }
  dialogConfirm() {
    this.$refs['validateForm'].validate((valid) => {
      if (valid) {
        this.showLoading();
        const { useLog, ..._params } = this.formData;
        const params = {
          ..._params,
          publicType: this.merchantId ? undefined : _params.publicType,
          useLog: _params.publicType && _params.id && this.merchantId ? {
            id: useLog.id,
            composeId: _params.id,
            hideStatus: Number(!useLog.preHideStatus),
            merchantId: this.merchantId,
            sort: useLog.sort,
          } : undefined,
          merchantId: _params.id ? undefined : this.merchantId
        }
        saveAppCompose(params).then(res => {
          this.dialogClose();
          this.resetPages();
          this.getData();
        }).finally(() => {
          this.hideLoading();
        });
      } else {
        console.log('error submit!!');
        return false;
      }
    })
  }


}
</script>



<style lang="scss" scoped src="./style.scss">
</style>